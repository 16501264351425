import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import MaskedInput from "../../custom-input/masked-input";
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import SSNInput from "../../custom-input/ssn-masked-input";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// mui icons
import { ErrorOutlined } from "@mui/icons-material";

// helper utils
import {
	maritalStatusOptions,
	citizenshipTypeOptions,
	countryOptions,
	legalSpouseOptions,
	relationshipTypeOptions,
} from "../../../utils/select-options";
import {
	isNotNullAndUndefinedAndEmpty,
	isNullOrUndefinedOrEmpty,
} from "../../../utils/filter-value";
import { timeZoneOffset } from "../../../utils/timezone-offset";
import { isValidSSN } from "../../../utils/validation-helpers";

// custom styles
import styles from "./index.module.scss";
import "./datepicker.scss";

const BorrowerInformation = ({
	borrowerIndex,
	coBorrower,
	borrowerData,
	foreignNationalIndex,
	setForeignNationalIndex,
	data,
	setData,
	disabled,
	error,
}) => {
	const [ssnNumber, setSsnNumber] = useState("");
	const [homePhone, setHomePhone] = useState("");
	const [workPhone, setWorkPhone] = useState("");
	const [cellPhone, setCellPhone] = useState("");

	useEffect(() => {
		console.log(
			"75 data ssn:",
			data[borrowerIndex]?.taxIdentificationIdentifier
		);

		setSsnNumber(data[borrowerIndex]?.taxIdentificationIdentifier);
		setHomePhone(data[borrowerIndex]?.homePhoneNumber);
		setWorkPhone(data[borrowerIndex]?.workPhoneNumber);
		setCellPhone(data[borrowerIndex]?.mobilePhone);
	}, [data]);

	const handleSSN = (value) => {
		console.log("40 val:", value);
		setSsnNumber(value);

		console.log("45 data:", data);

		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					return { ...data, taxIdentificationIdentifier: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleHomePhone = (value) => {
		setHomePhone(value);
		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					return { ...data, homePhoneNumber: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleWorkPhone = (value) => {
		setWorkPhone(value);
		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					return { ...data, workPhoneNumber: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleCellPhone = (value) => {
		setCellPhone(value);
		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					return { ...data, mobilePhone: value };
				} else {
					return data;
				}
			})
		);
	};

	const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

	const datepickerPattern =
		/^[A-Z][a-z]{2} [A-Z][a-z]{2} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \([A-Za-z\s]+\)$/;

	function formatDateToMMDDYYYY(dateString) {
		console.log("125 dateString:", dateString);

		// Remove the day of the week and time zone name
		const cleanedDateString = dateString
			?.toString()
			.replace(/^(.*?\d{4}\s+\d{2}:\d{2}:\d{2}).*$/, "$1");

		// Parse the cleaned date string
		const parsedDate = DateTime.fromFormat(
			cleanedDateString,
			"EEE MMM dd yyyy HH:mm:ss",
			{ zone: "utc" }
		);

		// Format the parsed date to "MM-dd-yyyy hh:mm a" format
		const formattedDate = parsedDate.toFormat("MM-dd-yyyy");

		console.log("140 formatted date:", formattedDate);
		return formattedDate;
	}

	const handleBirthDate = (dateValue) => {
		console.log("100 date:", dateValue);

		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					console.log("pattern iso 8601:", iso8601Pattern.test(dateValue));

					if (iso8601Pattern.test(dateValue)) {
						const formattedDate = timeZoneOffset(dateValue);
						console.log("130 formattedDate:", formattedDate);
						return { ...data, birthDate: formattedDate };
					}

					// Create a Date object from the date string
					const date = new Date(dateValue);
					// Get the year
					const year = date.getFullYear();
					console.log("160 year:", year);

					console.log("pattern custom:", datepickerPattern.test(dateValue));

					if (datepickerPattern.test(dateValue) && year > 1800) {
						const formattedDateTime = formatDateToMMDDYYYY(dateValue);

						console.log("160 formattedDateTime:", formattedDateTime);
						return { ...data, birthDate: formattedDateTime };
					}

					if (dateValue === null) {
						// clear btn clicked
						return { ...data, birthDate: undefined };
					}

					console.log("135 non-formatted date:", dateValue);
					return { ...data, birthDate: dateValue };
				} else {
					return data;
				}
			})
		);
	};

	useEffect(() => {
		console.log("150 date:", data[borrowerIndex]?.birthDate);
		const dateValue = data[borrowerIndex]?.birthDate;

		console.log("150 match pattern:", iso8601Pattern.test(dateValue));

		if (iso8601Pattern.test(dateValue)) {
			setData(
				data.map((data, index) => {
					if (borrowerIndex === index) {
						const formattedDate = timeZoneOffset(dateValue);

						console.log("200 formattedDate:", formattedDate);
						return { ...data, birthDate: formattedDate };
					} else {
						return data;
					}
				})
			);
		}
	}, [data, borrowerIndex]);

	useEffect(() => {
		if (coBorrower) {
			setData(
				data.map((data, index) => {
					if (borrowerIndex === index) {
						return { ...data, hmdaNoCoApplicantIndicator: false };
					} else {
						return data;
					}
				})
			);
		}
	}, [coBorrower]);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text={coBorrower ? "Co Borrower Information" : "Borrower Information"}
					fontType="semibold"
				/>

				<Body3 text="Provide details about the borrower" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* first name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-first-name`}
						>
							<Heading6
								text="First Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-first-name`}
							placeholder="First Name"
							value={data[borrowerIndex]?.firstName}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return { ...data, firstName: event.target.value };
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								!coBorrower &&
								error &&
								(data[borrowerIndex]?.firstName === undefined ||
									data[borrowerIndex]?.firstName === "")
							}
							showEndIcon={
								!coBorrower &&
								error &&
								(data[borrowerIndex]?.firstName === undefined ||
									data[borrowerIndex]?.firstName === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>
					{/* middle name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-middle-name`}
						>
							<Heading6
								text="Middle Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-middle-name`}
							placeholder="Middle Name"
							value={data[borrowerIndex]?.middleName}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return { ...data, middleName: event.target.value };
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* last name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-last-name`}
						>
							<Heading6
								text="Last Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-last-name`}
							placeholder="Last Name"
							value={data[borrowerIndex]?.lastName}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return { ...data, lastName: event.target.value };
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? isNotNullAndUndefinedAndEmpty(
											data[borrowerIndex]?.firstName
									  ) && isNullOrUndefinedOrEmpty(data[borrowerIndex]?.lastName)
									: isNullOrUndefinedOrEmpty(data[borrowerIndex]?.lastName))
							}
							showEndIcon={
								error &&
								(coBorrower
									? isNotNullAndUndefinedAndEmpty(
											data[borrowerIndex]?.firstName
									  ) && isNullOrUndefinedOrEmpty(data[borrowerIndex]?.lastName)
									: isNullOrUndefinedOrEmpty(data[borrowerIndex]?.lastName))
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>

					{/* date of birth */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}birth-date-picker`}
						>
							<Heading6
								text="Date of Birth"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<DatePicker
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}birth-date-picker`}
							className={
								disabled
									? styles.datepicker + " " + styles.disabledDatepicker
									: error &&
									  (coBorrower
											? isNotNullAndUndefinedAndEmpty(
													data[borrowerIndex]?.firstName
											  ) &&
											  isNullOrUndefinedOrEmpty(data[borrowerIndex]?.birthDate)
											: isNullOrUndefinedOrEmpty(
													data[borrowerIndex]?.birthDate
											  ))
									? styles.datepicker + " datepicker-error"
									: styles.datepicker
							}
							format="MM-dd-yyyy"
							value={
								data[borrowerIndex]?.birthDate !== undefined &&
								data[borrowerIndex]?.birthDate !== "" &&
								typeof data[borrowerIndex]?.birthDate === "string"
									? new Date(data[borrowerIndex]?.birthDate)
									: data[borrowerIndex]?.birthDate
							}
							onChange={handleBirthDate}
							disabled={disabled}
							maxDate={new Date()}
							error={
								error &&
								(coBorrower
									? isNotNullAndUndefinedAndEmpty(
											data[borrowerIndex]?.firstName
									  ) &&
									  isNullOrUndefinedOrEmpty(data[borrowerIndex]?.birthDate)
									: isNullOrUndefinedOrEmpty(data[borrowerIndex]?.birthDate))
							}
						/>
					</Stack>

					{/* marital status */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}select-marital-status`}
						>
							<Heading6
								text="Marital Status"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}select-marital-status`}
							displayEmpty={true}
							value={data[borrowerIndex]?.maritalStatusType || ""}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												maritalStatusType: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							options={maritalStatusOptions}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? isNotNullAndUndefinedAndEmpty(
											data[borrowerIndex]?.firstName
									  ) &&
									  isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.maritalStatusType
									  )
									: isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.maritalStatusType
									  ))
							}
						/>
					</Stack>

					{/* legal spouse */}
					{data[borrowerIndex]?.maritalStatusType === "Unmarried" && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel
								htmlFor={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-legal-spouse`}
							>
								<Heading6
									text="Is there a person who is not your legal spouse but who currently has
							real property rights similar to those of a legal spouse?"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-legal-spouse`}
								displayEmpty={true}
								value={
									data[borrowerIndex]?.legalOtherThanSpouse?.toString() || ""
								}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (borrowerIndex === index) {
												return {
													...data,
													legalOtherThanSpouse:
														event.target.value === "true" ? true : false,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={legalSpouseOptions}
								disabled={disabled}
							/>
						</Stack>
					)}

					{/* relationship type */}
					{data[borrowerIndex]?.maritalStatusType === "Unmarried" && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel
								htmlFor={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-relationship-type`}
							>
								<Heading6
									text="Domestic Relationship Type"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-relationship-type`}
								displayEmpty={true}
								value={data[borrowerIndex]?.domesticRelationshipType || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (borrowerIndex === index) {
												return {
													...data,
													domesticRelationshipType: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={relationshipTypeOptions}
								disabled={disabled}
							/>
						</Stack>
					)}

					{/* citizenship type */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}select-citizenship-type`}
						>
							<Heading6
								text="Citizenship"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}select-citizenship-type`}
							displayEmpty={true}
							value={
								data[borrowerIndex]?.urla2020CitizenshipResidencyType || ""
							}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											if (
												event.target.value === "Foreign National" ||
												(data[borrowerIndex]
													?.urla2020CitizenshipResidencyType ===
													"NonPermanentResidentAlien" &&
													foreignNationalIndex.includes(borrowerIndex))
											) {
												console.log("595 foreign index:", foreignNationalIndex);
												console.log("595 borrower index:", borrowerIndex);

												setForeignNationalIndex([
													...foreignNationalIndex,
													borrowerIndex,
												]);

												return {
													...data,
													urla2020CitizenshipResidencyType: "Foreign National",
												};
											} else if (
												event.target.value === "NonPermanentResidentAlien" ||
												data[borrowerIndex]
													?.urla2020CitizenshipResidencyType ===
													"NonPermanentResidentAlien"
											) {
												let foreignNationalArr = [...foreignNationalIndex];

												if (foreignNationalArr.indexOf(borrowerIndex) > -1) {
													// only splice array when item is found
													foreignNationalArr.splice(
														foreignNationalArr.indexOf(borrowerIndex),
														1
													); // 2nd parameter means remove one item only
												}

												console.log(
													"630 foreign national arr:",
													foreignNationalArr
												);
												setForeignNationalIndex(foreignNationalArr);

												return {
													...data,
													urla2020CitizenshipResidencyType: event.target.value,
												};
											} else {
												let foreignNationalArr = [...foreignNationalIndex];

												if (foreignNationalArr.indexOf(borrowerIndex) > -1) {
													// only splice array when item is found
													foreignNationalArr.splice(
														foreignNationalArr.indexOf(borrowerIndex),
														1
													); // 2nd parameter means remove one item only
												}

												console.log(
													"650 foreign national arr:",
													foreignNationalArr
												);
												setForeignNationalIndex(foreignNationalArr);

												return {
													...data,
													urla2020CitizenshipResidencyType: event.target.value,
													urla2020CountryOfCitizenship: "",
												};
											}
										} else {
											return data;
										}
									})
								)
							}
							options={citizenshipTypeOptions}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? isNotNullAndUndefinedAndEmpty(
											data[borrowerIndex]?.firstName
									  ) &&
									  isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.urla2020CitizenshipResidencyType
									  )
									: isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.urla2020CitizenshipResidencyType
									  ))
							}
						/>
					</Stack>

					{/* citizenship country */}
					{(data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
						"NonPermanentResidentAlien" ||
						data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
							"Foreign National") && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel
								htmlFor={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-citizenship-country`}
							>
								<Heading6
									text="Country of Citizenship"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-citizenship-country`}
								displayEmpty={true}
								value={data[borrowerIndex]?.urla2020CountryOfCitizenship || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (borrowerIndex === index) {
												return {
													...data,
													urla2020CountryOfCitizenship: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={countryOptions}
								disabled={disabled}
								error={
									error &&
									(coBorrower
										? isNotNullAndUndefinedAndEmpty(
												data[borrowerIndex]?.firstName
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.urla2020CountryOfCitizenship
										  )
										: isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.urla2020CountryOfCitizenship
										  ))
								}
							/>
						</Stack>
					)}

					{/* ssn */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-input-ssn`}
						>
							<Heading6
								text="SSN"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SSNInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-input-ssn`}
							style={{
								border:
									error &&
									(coBorrower
										? isNotNullAndUndefinedAndEmpty(
												data[borrowerIndex]?.firstName
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.taxIdentificationIdentifier
										  ) &&
										  // || !isValidSSN(
										  // 		data[borrowerIndex]?.taxIdentificationIdentifier
										  // 	)
										  "3px solid #f00"
										: isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.taxIdentificationIdentifier
										  ) &&
										  // || !isValidSSN(
										  // 		data[borrowerIndex]?.taxIdentificationIdentifier
										  // 	)
										  "3px solid #f00"),
							}}
							placeholder="___-___-___"
							onUnmaskedSSNChange={handleSSN}
							disabled={disabled}
							unmaskedSSN={ssnNumber}
						/>
					</Stack>
				</Stack>

				{/* contact information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* cell phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-cell-phone`}
						>
							<Heading6
								text="Cell Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-cell-phone`}
							placeholder="[XXX] XXX-XXXX"
							style={{
								border:
									error &&
									(coBorrower
										? isNotNullAndUndefinedAndEmpty(
												data[borrowerIndex]?.firstName
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.homePhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.workPhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.mobilePhone
										  ) &&
										  "3px solid #f00"
										: isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.homePhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.workPhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.mobilePhone
										  ) &&
										  "3px solid #f00"),
							}}
							type="phone"
							value={cellPhone}
							onInputChange={handleCellPhone}
							disabled={disabled}
						/>
					</Stack>
					{/* work phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-work-phone`}
						>
							<Heading6
								text="Work Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-work-phone`}
							placeholder="[XXX] XXX-XXXX"
							style={{
								border:
									error &&
									(coBorrower
										? isNotNullAndUndefinedAndEmpty(
												data[borrowerIndex]?.firstName
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.homePhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.workPhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.mobilePhone
										  ) &&
										  "3px solid #f00"
										: isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.homePhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.workPhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.mobilePhone
										  ) &&
										  "3px solid #f00"),
							}}
							type="phone"
							value={workPhone}
							onInputChange={handleWorkPhone}
							disabled={disabled}
						/>
					</Stack>

					{/* home phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-home-phone`}
						>
							<Heading6
								text="Home Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}masked-home-phone`}
							placeholder="[XXX] XXX-XXXX"
							style={{
								border:
									error &&
									(coBorrower
										? isNotNullAndUndefinedAndEmpty(
												data[borrowerIndex]?.firstName
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.homePhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.workPhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.mobilePhone
										  ) &&
										  "3px solid #f00"
										: isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.homePhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.workPhoneNumber
										  ) &&
										  isNullOrUndefinedOrEmpty(
												data[borrowerIndex]?.mobilePhone
										  ) &&
										  "3px solid #f00"),
							}}
							type="phone"
							value={homePhone}
							onInputChange={handleHomePhone}
							disabled={disabled}
						/>
					</Stack>

					{/* email */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-email`}
						>
							<Heading6
								text="Email"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-email`}
							placeholder="Email"
							value={data[borrowerIndex]?.emailAddressText}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												emailAddressText: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? isNotNullAndUndefinedAndEmpty(
											data[borrowerIndex]?.firstName
									  ) &&
									  isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.emailAddressText
									  )
									: isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.emailAddressText
									  ))
							}
							showEndIcon={
								error &&
								(coBorrower
									? isNotNullAndUndefinedAndEmpty(
											data[borrowerIndex]?.firstName
									  ) &&
									  isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.emailAddressText
									  )
									: isNullOrUndefinedOrEmpty(
											data[borrowerIndex]?.emailAddressText
									  ))
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>
				</Stack>
				{/* dependent information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* dependants */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-dependants`}
						>
							<Heading6
								text="Dependants"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-dependants`}
							type="number"
							fullWidth={true}
							inputProps={{ min: 0 }}
							value={data[borrowerIndex]?.dependentCount || 0}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												dependentCount: Math.max(
													0,
													Math.min(100, Number(event.target.value))
												),
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* dependants ages */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-dependants-ages`}
						>
							<Heading6
								text="Dependants Ages"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-dependants-ages`}
							type="text"
							placeholder="Ages"
							fullWidth={true}
							value={data[borrowerIndex]?.dependentsAgesDescription}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												dependentsAgesDescription: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default BorrowerInformation;
