import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// mui components
import { Stack, Tabs, Tab } from "@mui/material";

// custom components
import EmptyTextContainer from "../../empty-text-container";
import { TabPanel } from "../../tab-panel";
import RequiredError from "../../required-error";
import Heading6 from "../../typography/heading-06";
import EligibleFilter from "../../loan-form-card/eligible-filter";
import IneligibleFilter from "../../loan-form-card/ineligible-filter";
import TemporaryDrawer from "../../temporary-drawer";

// api slices
import { useGetLoanMutation } from "../../../features/get-loan-data/getLoanApiSlice";
import { useGetEligibleProductsMutation } from "../../../features/get-eligible-products/getEligibleProductsApiSlice";
import { useGetEligibleProductDetailsMutation } from "../../../features/get-eligible-product-details/getEligibleProductDetailsApiSlice";
import { useGetIneligibleProductsMutation } from "../../../features/get-ineligible-products/getIneligibleProductsApiSlice";
import { useUpdateLoanWithProductMutation } from "../../../features/update-loan-with-product/updateLoanWithProductApiSlice";
import { usePushDataToEncompassMutation } from "../../../features/push-data-to-encompass/pushDataToEncompassApiSlice";
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useCreateOBLockRequestMutation } from "../../../features/create-OB-lock-request/createOBLockRequestApiSlice";
import { useCheckQueueStatusMutation } from "../../../features/check-queue-status/checkQueueStatusApiSlice";

// redux slice
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// custom modal
import LoaderTextModal from "../../modal/loader-text-modal";
import SuccessModal from "../../modal/success-modal";
import ErrorModal from "../../modal/error-modal";
import PricingModal from "../../modal/pricing-modal";
import ProcessingModal from "../../modal/processing-modal";

//utils
import {
	LOAN_CHANNEL_CONSTANTS,
	TPO_SECONDARY,
} from "../../../utils/common-constants";
import {
	selectedUserWithAccessLevel,
	selectedUserWithLoanChannel,
} from "../../../utils/common-helpers";

// custom styles
import styles from "./index.module.scss";

const LoanPricingCard = (
	{
		loanGuid,
		setIsPriceLoanDisabled,
		handleTabChange,
		getFieldValuesOnLoanSummary,
	},
	ref
) => {
	const params = useParams();

	// Expose the childMethod using a ref
	React.useImperativeHandle(ref, () => ({
		handleSubmitApplication: () => handleSubmitApplication(),
	}));

	console.log("50 loan id:", loanGuid);

	const [tabValue, setTabValue] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const [getLoan, { isLoading: isGettingLoan }] = useGetLoanMutation();
	const [field, { isLoading: fetchingValues }] = useFieldMutation();
	const [getEligibleProducts, { isLoading: isGettingEligibleProducts }] =
		useGetEligibleProductsMutation();
	const [
		getEligibleProductDetails,
		{ isLoading: isGettingEligibleProductDetails },
	] = useGetEligibleProductDetailsMutation();
	const [getIneligibleProducts, { isLoading: isGettingIneligibleProducts }] =
		useGetIneligibleProductsMutation();

	const [updateLoanWithProduct, { isLoading: isUpdatingLoanWithProduct }] =
		useUpdateLoanWithProductMutation();

	const [pushDataToEncompass, { isLoading: isPushingDataToEncompass }] =
		usePushDataToEncompassMutation();

	const [createOBLockRequest, { isLoading: isCreatingOBLockRequest }] =
		useCreateOBLockRequestMutation();

	const [checkOrderStatus] = useCheckQueueStatusMutation();

	console.log("23 params:", params);
	console.log("23 loan id:", loanGuid);

	const [loanData, setLoanData] = useState({});
	const [eligibleProducts, setEligibleProducts] = useState([]);
	const [expandedProduct, setExpandedProduct] = useState("");
	const [eligibleProductDetails, setEligibleProductDetails] = useState({});
	const [selectedRow, setSelectedRow] = useState({});
	const [selectedProduct, setSelectedProduct] = useState({});
	console.log("40 selected product:", selectedProduct);
	console.log("40 selected eligible sub product:", selectedRow);

	const [ineligibleProducts, setIneligibleProducts] = useState([]);

	const [drawerData, setDrawerData] = useState({});
	const [drawerType, setDrawerType] = useState("");
	const [drawerOpen, setDrawerOpen] = useState({});

	const [loanProgram, setLoanProgram] = useState("");
	const [loanEmail, setLoanEmail] = useState("");
	const [orgLoanChannel, setOrgLoanChannel] = useState("");

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const handleOpenDrawer = () => {
		setDrawerOpen({
			right: true,
		});
	};

	const handleCloseDrawer = () => {
		setDrawerOpen({
			right: false,
		});
	};

	console.log("100 all products:", eligibleProducts);
	console.log("100 expanded id:", expandedProduct);
	console.log("100 product details:", eligibleProductDetails);

	const toggleDrawer = (event, drawerType, rowData) => {
		if (drawerType !== undefined && drawerType !== "") {
			console.log("eligible products:", eligibleProducts);
			console.log("expanded product:", expandedProduct);

			const parentProductDetails = eligibleProducts?.data?.products?.filter(
				(product) => product?.productId === expandedProduct
			);

			console.log("parent product details:", parentProductDetails);

			setDrawerData({
				loanData: loanData,
				parentProductDetails: parentProductDetails[0],
				productDetails: eligibleProductDetails,
				rowData: rowData,
			});
			setDrawerType(drawerType);
			handleOpenDrawer();
		} else {
			setDrawerType("");
			handleCloseDrawer();
		}
	};

	const [processingText, setProcessingText] = useState("");
	const [processingModalVisible, setProcessingModalVisible] = useState(false);

	const handleOpenProcessingModal = () => {
		setProcessingModalVisible(true);
	};

	const handleCloseProcessingModal = () => {
		setProcessingModalVisible(false);
	};

	const [successText, setSuccessText] = useState("");
	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
		handleTabChange({}, 0);
		getFieldValuesOnLoanSummary();
	};

	const [errorText, setErrorText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const [pricingModal, setPricingModal] = useState(false);

	const handleOpenPricingModal = () => {
		setPricingModal(true);
	};

	const handleClosePricingModal = () => {
		setPricingModal(false);
	};

	const handleSubmitPricingModal = async () => {
		console.log("100 eligible products:", eligibleProducts);
		console.log("100 expanded product:", expandedProduct);
		console.log("85 selected sub products", selectedRow);

		var formData = new FormData();
		formData.append("ob_loan_id", eligibleProducts?.data?.ob_loan_id);
		formData.append(
			"ob_business_channel_id",
			eligibleProducts?.data?.ob_business_channel_id
		);
		formData.append(
			"ob_originator_id",
			eligibleProducts?.data?.ob_originator_id
		);
		formData.append("product_id", expandedProduct);
		formData.append("search_id", eligibleProducts?.data?.searchId);
		formData.append("rate", selectedRow?.rate);
		formData.append("price", selectedRow?.price);
		formData.append("lock_period", selectedRow?.lockPeriod);

		// dispatch API and open modal if successful
		try {
			const updatedLoanWithProduct = await updateLoanWithProduct({
				formData,
			}).unwrap();
			console.log("125 updated loan with product:", updatedLoanWithProduct);

			if (updatedLoanWithProduct?.message?.toLowerCase()?.includes("success")) {
				try {
					const createOBLockRequestData = await createOBLockRequest({
						formData,
					}).unwrap();

					if (
						createOBLockRequestData?.message?.toLowerCase()?.includes("success")
					) {
						// add logic if required
					}
				} catch (err) {
					console.log(
						"LoanPricingCard:handleSubmitPricingModal:createOBLockRequest:err",
						err
					);

					handleClosePricingModal();
					if (
						err?.data?.data?.errors?.includes(
							"requested a lock with Expired pricing"
						) ||
						err?.data?.data?.errors?.includes(
							"requesting a lock outside of your configured Lock Desk hours"
						)
					) {
						setErrorText(
							"Your lock request for this loan was not able to be processed at this time because there is no active pricing available. This is because the request was submitted after hours and or pricing is currently being updated. Please request when pricing is active or contact the Logan Pricing Desk."
						);
						handleOpenErrorModal();
					} else {
						setErrorText(err?.data?.message);
						handleOpenErrorModal();
					}
					return;
				}

				var productFormData = new FormData();
				productFormData.append(
					"ob_loan_id",
					eligibleProducts?.data?.ob_loan_id
				);
				productFormData.append(
					"ob_business_channel_id",
					eligibleProducts?.data?.ob_business_channel_id
				);
				productFormData.append(
					"ob_originator_id",
					eligibleProducts?.data?.ob_originator_id
				);

				const pushToEncompassResponse = await pushDataToEncompass({
					formData: productFormData,
				}).unwrap();
				console.log("90 push to encompass response:", pushToEncompassResponse);

				if (pushToEncompassResponse?.status === "Success") {
					checkQueueStatusInterval = setTimeout(
						() => checkQueueStatus(pushToEncompassResponse?.data?.pushQueueId),
						0
					);
				} else {
					console.log("290 error else:", pushToEncompassResponse);

					handleClosePricingModal();
					setErrorText(
						pushToEncompassResponse?.error ||
							"Error updating loan with OB Product."
					);
					handleOpenErrorModal();
				}
			} else {
				handleClosePricingModal();
				setErrorText("Error updating loan with OB Product.");
				handleOpenErrorModal();
			}
		} catch (err) {
			console.log("290 error catch:", err);

			handleClosePricingModal();
			setErrorText(err?.data?.message);
			handleOpenErrorModal();
		}
	};

	const [error, setError] = useState("");

	const [isCheckingCreditReissueStatus, setIsCheckingCreditReissueStatus] =
		useState(false);
	let checkQueueStatusInterval = null;

	const checkQueueStatus = async (queueId) => {
		setIsCheckingCreditReissueStatus(true);

		var formData = new FormData();
		formData.append("ob_loan_id", eligibleProducts?.data?.ob_loan_id);
		formData.append(
			"ob_business_channel_id",
			eligibleProducts?.data?.ob_business_channel_id
		);
		formData.append(
			"ob_originator_id",
			eligibleProducts?.data?.ob_originator_id
		);
		formData.append("ob_queue_id", queueId);

		try {
			const response = await checkOrderStatus({
				queueId,
				formData,
			}).unwrap();
			console.log("265 queue status response:", response);

			clearTimeout(checkQueueStatusInterval);
			checkQueueStatusInterval = null;

			console.log("285 queue id:", queueId?.toString());

			let queueData = response?.data?.filter(
				(item) => item?.pushId === queueId?.toString()
			);

			console.log("290 data:", queueData);
			console.log("290 status:", queueData[0]?.pushRequestStatus);

			if (queueData[0]?.pushRequestStatus?.toLowerCase()?.includes("success")) {
				// success scenario
				console.log("300 queue successful");
				setIsCheckingCreditReissueStatus(false);
				handleClosePricingModal();
				handleOpenSuccessModal();
				setSuccessText(
					`Your lock request has been submitted to Logan Finance.`
				);
			} else if (
				queueData[0]?.pushRequestStatus?.toLowerCase()?.includes("progress")
			) {
				// In-progress scenario
				console.log("300 queue in-progress");
				checkQueueStatusInterval = setTimeout(
					() => checkQueueStatus(queueId),
					3000
				);
			} else {
				// error scenario
				console.log("300 queue error");
				setIsCheckingCreditReissueStatus(false);
				handleClosePricingModal();
				setErrorText(
					response?.error ||
						`Error pushing data from OB to Encompass with pushId ${queueId}`
				);
				handleOpenErrorModal();
			}
		} catch (err) {
			console.log("300 catch error:", err);
			setIsCheckingCreditReissueStatus(false);

			clearTimeout(checkQueueStatusInterval);
			checkQueueStatusInterval = null;

			handleClosePricingModal();
			setErrorText(
				`Error pushing data from OB to Encompass with pushId ${queueId}`
			);
			handleOpenErrorModal();
		}
	};

	const getIneligibleProductList = async ({
		ob_business_channel_id,
		ob_originator_id,
		ob_loan_id,
		searchId,
	}) => {
		try {
			console.log("200 eligibleProducts:", eligibleProducts);

			const ineligibleProductList = await getIneligibleProducts({
				ob_business_channel_id,
				ob_originator_id,
				ob_loan_id,
				searchId,
			}).unwrap();

			console.log(
				"120 ineligible products:",
				ineligibleProductList?.data?.ineligibleProducts
			);
			setIneligibleProducts(ineligibleProductList?.data?.ineligibleProducts);
		} catch (err) {
			console.log("270 error:", err);
			setErrorText(
				err?.data?.message?.errors || "Error fetching ineligible products."
			);
			handleOpenErrorModal();
		}
	};

	const getEligibleProductList = async () => {
		console.log("275 loan id:", loanGuid);

		try {
			const loanDetails = await getLoan({
				loanid: loanGuid,
			}).unwrap();

			console.log("120 loan data:", loanDetails?.response);
			console.log("120 loan number:", loanDetails?.response?.loanNumber);

			setLoanData(loanDetails?.response);

			let propertyType;

			let isPropertyNotLocatedInProject =
				loanDetails?.response?.notInProjectIndicator;

			if (isPropertyNotLocatedInProject) {
				// Single Family or 2-4 Unit Property or 5-8 Unit Residential or 5-8 Unit Mixed Use
				propertyType = "SingleFamily";
			} else {
				propertyType = "";
			}

			console.log("360 property type:", propertyType);

			console.log("450 userDetails:", userDetails);
			console.log("450 impersonatedUser:", impersonatedUser);
			console.log(
				"450 selected:",
				selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel
			);

			console.log(
				"400 selected email:",
				selectedUserWithLoanChannel(userDetails, impersonatedUser)?.email
			);

			let loanChannel;
			let email;
			let regex = /\(([^)]+)\)/;

			if (
				(impersonatedUser?.email === undefined ||
					impersonatedUser?.email === null) &&
				userDetails?.user_roles?.includes("admin") === true
			) {
				console.log("500 email:", loanEmail);
				console.log("500 org loan channel:", orgLoanChannel);
				console.log("500 loan channel:", orgLoanChannel?.match(regex)?.[1]);
				loanChannel = orgLoanChannel?.match(regex)?.[1];
				email = loanEmail;
			} else {
				loanChannel = selectedUserWithLoanChannel(
					userDetails,
					impersonatedUser
				)?.loan_channel;
				email = selectedUserWithLoanChannel(
					userDetails,
					impersonatedUser
				)?.email;
			}

			var formData = new FormData();
			formData.append("encompass_loan_guid", loanGuid);
			formData.append(
				"encompass_loan_number",
				loanDetails?.response?.loanNumber
			);
			formData.append("propertyType", propertyType);
			formData.append("loan_channel", loanChannel?.toUpperCase());
			formData.append("broker_email", email);

			const eligibleProductList = await getEligibleProducts({
				formData,
			}).unwrap();

			console.log("120 eligible products:", eligibleProductList);

			if (eligibleProductList?.status?.toLowerCase()?.includes("success")) {
				const filteredEligibleProducts =
					eligibleProductList?.data?.products?.filter(
						(product) => product.productName === loanProgram
					);
				const tempEligibleProductList = {
					...eligibleProductList,
					data: {
						...eligibleProductList?.data,
						products: [...filteredEligibleProducts],
					},
				};
				console.log(
					"LoanPricingCard:getEligibleProductList:tempEligibleProductList",
					tempEligibleProductList
				);
				setEligibleProducts(tempEligibleProductList);

				getIneligibleProductList({
					ob_business_channel_id:
						eligibleProductList?.data?.ob_business_channel_id,
					ob_originator_id: eligibleProductList?.data?.ob_originator_id,
					ob_loan_id: eligibleProductList?.data?.ob_loan_id,
					searchId: eligibleProductList?.data?.searchId,
				});
			}
		} catch (err) {
			console.log("LoanPricingCard:getEligibleProductList:err", err);
			if (err?.data?.data?.errors?.includes("LockPending status")) {
				setErrorText(
					"A lock request cannot be made at this time because a lock request is currently pending for this loan."
				);
			} else if (
				err?.data?.message?.toLowerCase()?.includes("locked by another user")
			) {
				setErrorText(
					"This Loan is currently Locked, If you need to update the lock on this loan, please contact Logan's lock desk."
				);
			} else {
				setErrorText(
					err?.data?.message?.errors || "Error fetching eligible products."
				);
			}
			handleOpenErrorModal();
		}
	};

	const handleGetEligibleProductDetails = async (productId) => {
		console.log("160 product id:", productId);

		try {
			const eligibleProductResponse = await getEligibleProductDetails({
				ob_business_channel_id: eligibleProducts?.data?.ob_business_channel_id,
				ob_originator_id: eligibleProducts?.data?.ob_originator_id,
				ob_loan_id: eligibleProducts?.data?.ob_loan_id,
				search_id: eligibleProducts?.data?.searchId,
				product_id: productId,
			}).unwrap();

			console.log("120 eligible product details:", eligibleProductResponse);

			setEligibleProductDetails(eligibleProductResponse);
		} catch (err) {
			console.log("270 error:", err);
			setErrorText(
				err?.data?.message?.errors || "Error fetching eligible product details."
			);
			handleOpenErrorModal();
		}
	};

	useEffect(() => {
		console.log("545 loan email:", loanEmail);

		if (loanEmail !== undefined && loanEmail !== "") {
			getEligibleProductList();
		}
	}, [loanEmail]);

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setLoanProgram(findFieldValue(fieldValues, "1401"));
		setLoanEmail(findFieldValue(fieldValues, "TPO.X63"));
		setOrgLoanChannel(findFieldValue(fieldValues, "TPO.X14"));
	};

	const getFieldValues = async (loanId) => {
		console.log("135 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "1401,TPO.X63,TPO.X14",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		console.log("555 loan Guid:", loanGuid);

		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	useEffect(() => {
		if (Object.keys(selectedRow)?.length > 0) {
			setIsPriceLoanDisabled(false);
		} else {
			setIsPriceLoanDisabled(true);
		}
	}, [selectedRow]);

	const handleSubmitApplication = async () => {
		console.log("502 params id:", params?.id);
		console.log("502 loan id:", loanGuid);

		setError("");
		handleOpenPricingModal();
	};

	const handleToggleExpand = (productId) => {
		if (expandedProduct === productId) {
			setExpandedProduct("");
		} else {
			setExpandedProduct(productId);
			handleGetEligibleProductDetails(productId);
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="pricing tabs"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						<Tab
							className={
								tabValue === 0
									? styles.tabBtnContainer + " " + styles.activeTabBtnContainer
									: styles.tabBtnContainer
							}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text={`Eligible Pricing Results (${
											eligibleProducts?.data?.products
												? eligibleProducts?.data?.products?.length
												: 0
										})`}
										fontType="semibold"
										extraClass={
											tabValue === 0 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(0)}
						/>
						<Tab
							className={
								tabValue === 1
									? styles.tabBtnContainer + " " + styles.activeTabBtnContainer
									: styles.tabBtnContainer
							}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text={`Ineligible Pricing Results (${
											ineligibleProducts ? ineligibleProducts?.length : 0
										})`}
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(1)}
						/>
					</Tabs>
				</Stack>

				<TabPanel
					index={0}
					value={tabValue}
					className={tabValue === 0 ? styles.tabPanelContainer : ""}
				>
					{eligibleProducts?.data?.products?.length > 0 ? (
						<EligibleFilter
							eligibleProducts={eligibleProducts?.data?.products}
							expandedProduct={expandedProduct}
							toggleExpand={handleToggleExpand}
							eligibleProductDetails={eligibleProductDetails?.data}
							selectedRow={selectedRow}
							setSelectedRow={setSelectedRow}
							setSelectedProduct={setSelectedProduct}
							toggleDrawer={toggleDrawer}
							hidePriceColumn={
								selectedUserWithAccessLevel(userDetails, impersonatedUser)
									?.access_level === TPO_SECONDARY
									? false
									: selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									  selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
							}
							hidePointsPercentageColumn={
								selectedUserWithAccessLevel(userDetails, impersonatedUser)
									?.access_level === TPO_SECONDARY
									? false
									: selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									  selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
							}
							hidePointsValueColumn={
								selectedUserWithAccessLevel(userDetails, impersonatedUser)
									?.access_level === TPO_SECONDARY
									? false
									: selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									  selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
							}
							hideEligibleProductFilters={true}
						/>
					) : (
						<EmptyTextContainer
							headerText="No products available"
							bodyText={`Please click the Ineligible button above to see why no products are available`}
						/>
					)}
				</TabPanel>

				<TabPanel
					index={1}
					value={tabValue}
					className={tabValue === 1 ? styles.tabPanelContainer : ""}
				>
					{ineligibleProducts?.length > 0 ? (
						<IneligibleFilter ineligibleProducts={ineligibleProducts || []} />
					) : (
						<EmptyTextContainer
							headerText="No products available"
							bodyText={`There are no Ineligible products available for this loan`}
						/>
					)}
				</TabPanel>
			</Stack>

			{/* loan flow error */}
			{error !== "" && (
				<RequiredError
					headerText="Missing fields"
					text={
						error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted above."
							: error
					}
				/>
			)}

			{/* drawer */}
			<TemporaryDrawer
				// drawer props
				drawerOpen={drawerOpen}
				drawerDirection="right"
				drawerType={drawerType}
				toggleDrawer={toggleDrawer}
				handleCloseDrawer={handleCloseDrawer}
				handleOpenProcessingModal={handleOpenProcessingModal}
				handleCloseProcessingModal={handleCloseProcessingModal}
				setProcessingText={setProcessingText}
				handleOpenSuccessModal={handleOpenSuccessModal}
				setSuccessText={setSuccessText}
				handleOpenErrorModal={handleOpenErrorModal}
				setErrorText={setErrorText}
				// custom props
				drawerData={drawerData}
			/>
			{/* custom modals */}
			<LoaderTextModal
				open={
					isGettingLoan ||
					isGettingEligibleProducts ||
					isGettingEligibleProductDetails ||
					isGettingIneligibleProducts
				}
				loadingTextLine1={"Obtaining Pricing & Eligibility"}
				loadingTextLine2={"This will take a few minutes"}
			/>
			<LoaderTextModal
				open={
					isCreatingOBLockRequest ||
					isUpdatingLoanWithProduct ||
					isPushingDataToEncompass ||
					isCheckingCreditReissueStatus
				}
				loadingTextLine1={"Submitting your Lock Request"}
				loadingTextLine2={"This will take a few minutes"}
			/>
			<ProcessingModal open={processingModalVisible} text={processingText} />
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successText}
			/>
			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
			<PricingModal
				open={pricingModal}
				data={selectedRow}
				productName={selectedProduct}
				confirmText="Request Rate Lock"
				handleConfirm={handleSubmitPricingModal}
				closeText="Go back"
				handleClose={handleClosePricingModal}
			/>
		</Stack>
	);
};

export default React.forwardRef(LoanPricingCard);
