import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// mui components
import { Box, IconButton, Stack, Tab, Tabs } from "@mui/material";

// custom components
import { TabPanel } from "../../tab-panel";
import RequiredError from "../../required-error";
import BorrowerInformation from "../../loan-form-card/borrower-information";
import PropertyDetails from "../../loan-form-card/property-details";
import LoanOriginator from "../../loan-form-card/loan-originator";
import LoanProcessor from "../../loan-form-card/loan-processor";
import PrimaryButton from "../../buttons/primary-button";
import Heading6 from "../../typography/heading-06";

// mui icons
import {
	AddOutlined,
	ArrowForwardOutlined,
	CloseOutlined,
} from "@mui/icons-material";

// api slice
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useGetLoanMutation } from "../../../features/get-loan-data/getLoanApiSlice";
import { useDeleteBorrowerPairMutation } from "../../../features/delete-borrower-pair/deleteBorrowerPairApiSlice";
import { useCreateBorrowerPairMutation } from "../../../features/create-borrower-pair/createBorrowerPairApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { useGetBrokerListByTitleMutation } from "../../../features/broker-search-title/brokerSearchTitleApiSlice";
import { useGetOrganizationDetailsMutation } from "../../../features/organization-detail/organizationDetailApiSlice";
import { useUpdateLoanMutation } from "../../../features/update-loan/updateLoanApiSlice";

// reducer slice
import { setBrokerListReducer } from "../../../features/broker-list/brokerListSlice";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import ErrorModal from "../../modal/error-modal";
import HelpTextModal from "../../modal/help-text-modal";

// helper utils
import { requiredFields } from "../../../utils/requiredFields";
import { handleSetPropertyDetails } from "../../../utils/loan-submission/set-property-details";

// custom styles
import styles from "./index.module.scss";

export const EditLoanApplication = ({
	setLoanApplicationStage,
	applicationData,
	setApplicationData,
	loanGuid,
	organizationName,
	currentStage,
}) => {
	console.log("24 application data edit:", applicationData);
	console.log("45 loan id:", loanGuid);

	const dispatch = useDispatch();

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [field, { isLoading: isFetchingFields }] = useFieldMutation();

	const [getLoan, { isLoading: loadingloanData }] = useGetLoanMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [updateLoan, { isLoading: updatingLoan }] = useUpdateLoanMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [createBorrowerPair, { isLoading: creatingBorrowerPair }] =
		useCreateBorrowerPairMutation();

	const [deleteBorrowerPair, { isLoading: deletingBorrowerPair }] =
		useDeleteBorrowerPairMutation();

	const [loanApplicationData, setLoanApplicationData] = useState([{}, {}]);
	const [borrowerResponseData, setBorrowerResponseData] = useState([]);

	const [foreignNationalIndex, setForeignNationalIndex] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const [propertyInfo, setPropertyInfo] = useState({});
	const [grossRent, setGrossRent] = useState("");

	const [homeownersInsurance, setHomeownersInsurance] = useState("");
	const [floodInsurance, setFloodInsurance] = useState("");
	const [propertyTaxes, setPropertyTaxes] = useState("");
	const [realEstateTaxes, setRealEstateTaxes] = useState("");
	const [hoaDues, setHoaDues] = useState("");

	const [getBrokerListByTitle, { isLoading: brokerListLoading }] =
		useGetBrokerListByTitleMutation();

	const [getOrganizationDetails, { isLoading: organizationDetailsLoading }] =
		useGetOrganizationDetailsMutation();
	const [organizationDetails, setOrganizationDetails] = useState({});

	const [loanOriginatorEmail, setLoanOriginatorEmail] = useState("");
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [loanOriginator, setLoanOriginator] = useState({});

	const [organizationLicense, setOrganizationLicense] = useState("");

	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const [loanProcessorEmail, setLoanProcessorEmail] = useState("");
	const [organizationProcessor, setOrganizationProcessor] = useState([]);
	const [loanProcessor, setLoanProcessor] = useState({});

	const [helpText, setHelpText] = useState("");
	const [helpTextOpen, setHelpTextOpen] = useState(false);

	const handleOpenHelpText = () => {
		setHelpTextOpen(true);
	};

	const handleCloseHelpText = () => {
		setHelpTextOpen(false);
	};

	useEffect(() => {
		const getBrokerListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Officer",
			}).unwrap();
			dispatch(setBrokerListReducer(searchResults));

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan originator updated:", updatedArr);
			setOrganizationUsers(updatedArr);
		};

		const getProcessorListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Processor",
			}).unwrap();

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan processor updated:", updatedArr);
			setOrganizationProcessor(updatedArr);
		};

		const getOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				organizationName,
			}).unwrap();
			setOrganizationDetails(searchResults);
		};

		if (
			organizationName !== null &&
			organizationName !== undefined &&
			organizationName !== ""
		) {
			getBrokerListFunc();
			getProcessorListFunc();
			getOrganizationDetailsFunc();
		}
	}, [organizationName]);

	useEffect(() => {
		if (
			Object.keys(loanOriginatorEmail)?.length !== 0 ||
			loanOriginatorEmail !== ""
		) {
			const originatorDetails = organizationUsers?.find(
				(item) => item?.user?.email === loanOriginatorEmail
			);

			setLoanOriginator(originatorDetails);
		}
	}, [loanOriginatorEmail]);

	useEffect(() => {
		if (
			Object.keys(loanProcessorEmail)?.length !== 0 ||
			loanProcessorEmail !== ""
		) {
			const processorDetails = organizationProcessor?.find(
				(item) => item?.user?.email === loanProcessorEmail
			);

			setLoanProcessor(processorDetails);
		}
	}, [loanProcessorEmail]);

	const [error, setError] = useState("");

	useEffect(() => {
		const getAllBorrowerPair = async () => {
			const borrowerPairData = await getBorrowerPair({
				loanGuid,
			}).unwrap();

			setBorrowerResponseData(borrowerPairData?.response);
		};

		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	useEffect(() => {
		const getLoanDetails = async () => {
			const loanData = await getLoan({
				loanid: loanGuid,
			}).unwrap();

			console.log("230 loan data:", loanData);

			if (loanData?.response !== undefined) {
				setGrossRent(
					loanData?.response?.subjectPropertyGrossRentalIncomeAmount
				);

				handleSetPropertyDetails(
					loanData?.response,
					setHomeownersInsurance,
					setFloodInsurance,
					setPropertyTaxes,
					setRealEstateTaxes,
					setHoaDues
				);
			}

			setLoanOriginatorEmail(loanData?.response?.tPO?.lOEmail || "");
			setLoanProcessorEmail(loanData?.response?.tPO?.lPEmail || "");

			setPropertyInfo(loanData?.response?.property || {});

			let borrowerPairs = loanData?.response?.applications
				?.map((application) => [application.borrower, application.coborrower])
				.flat();

			try {
				const getValues = await field({
					loan_guid: loanGuid,
					fields:
						"CX.FOREIGNNATLBORR1,CX.FOREIGNNATLBORR2,CX.FOREIGNNATLBORR3,CX.FOREIGNNATLBORR4,CX.FOREIGNNATLBORR5,CX.FOREIGNNATLBORR6",
				}).unwrap();

				console.log("175 all fields:", getValues?.response);
				console.log("240 borrowerPairs:", borrowerPairs);

				let updatedBorrowerPairs = [];
				let foreignIndex = [];

				if (getValues?.response?.length > 0) {
					for (let index = 0; index < borrowerPairs.length; index++) {
						const item = borrowerPairs[index];
						const updatedItem = { ...item };

						if (getValues?.response[index]?.value === "Yes") {
							updatedItem.urla2020CitizenshipResidencyType = "Foreign National";
							foreignIndex.push(index);
						}

						updatedBorrowerPairs.push(updatedItem);
					}

					console.log("250 updated foreign index:", foreignIndex);
					console.log("250 updated borrower pair:", updatedBorrowerPairs);
					setForeignNationalIndex(foreignIndex);
					setLoanApplicationData(updatedBorrowerPairs);
				}
			} catch (err) {
				console.log("270 error:", err);
			}
		};

		console.log("1130 loan guid:", loanGuid);
		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getLoanDetails();
		}
	}, [loanGuid]);

	const updateField = async (loanGuid, apiObj) => {
		let valuesArr = [
			{
				id: "CX.PROPERTY.COMMERCIAL.UNITS",
				value: apiObj?.property?.financedNumberOfUnits || "",
			},
			{ id: "NEWHUD.X223", value: "" },
			{ id: "NEWHUD.X224", value: "" },
			{ id: "389", value: "" },
			{ id: "1620", value: "" },
			{ id: 315, value: loanOriginator?.organization?.name },
			{ id: 313, value: loanOriginator?.organization?.city },
			{ id: 321, value: loanOriginator?.organization?.state },
			{ id: 323, value: loanOriginator?.organization?.zip },
			{ id: 3237, value: loanOriginator?.organization?.nmls_id },
		];

		console.log("300 foreign national:", foreignNationalIndex);

		// Loop through the range of indices (0 to 5) for 3 borrower pairs
		for (let index = 0; index < 6; index++) {
			// Create the id based on the index
			const id = `CX.FOREIGNNATLBORR${index + 1}`;

			// Check if the current index is in arrayIndex
			const value = foreignNationalIndex.includes(index) ? "Yes" : "No";

			// Push the object to valuesArr
			valuesArr.push({ id, value });
		}

		console.log("340 loan update values:", valuesArr);

		const updateFieldValues = await updateFields({
			loan_guid: loanGuid,
			field_values: valuesArr,
		}).unwrap();
		console.log("90 file res:", updateFieldValues);

		if (updateFieldValues?.response === "Field values updated successfully.") {
			setError("");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(currentStage + 1);
		} else {
			setError(updateFieldValues?.response);
		}
	};

	const handleNext = async () => {
		setError("");

		console.log("340 application data:", applicationData);
		console.log("340 loanApplication data:", loanApplicationData);

		if (
			!requiredFields(
				loanApplicationData,
				propertyInfo,
				grossRent,
				homeownersInsurance,
				propertyTaxes,
				loanOriginatorEmail,
				loanProcessorEmail,
				setError
			)
		) {
			return;
		}

		let borrowerPairData = [];

		console.log("445 loan applicationData:", loanApplicationData);
		let applications = [];

		for (let index = 0; index < loanApplicationData.length; index += 2) {
			if (
				loanApplicationData[index]?.urla2020CitizenshipResidencyType ===
				"Foreign National"
			) {
				loanApplicationData[index].urla2020CitizenshipResidencyType =
					"NonPermanentResidentAlien";
			}

			if (
				loanApplicationData[index + 1]?.urla2020CitizenshipResidencyType ===
				"Foreign National"
			) {
				loanApplicationData[index + 1].urla2020CitizenshipResidencyType =
					"NonPermanentResidentAlien";
			}

			applications.push({
				borrower: loanApplicationData[index],
				coBorrower: loanApplicationData[index + 1],
			});

			borrowerPairData.push([
				{
					borrower: loanApplicationData[index],
					coborrower: loanApplicationData[index + 1],
				},
			]);
		}

		console.log("415 applicationData:", applicationData);
		console.log("415 borrower pair data;", borrowerPairData);

		// Update the monthly payment for the specified fee type
		let updatedFees = [];

		if (
			applicationData?.fees === undefined ||
			applicationData?.fees?.length === 0
		) {
			updatedFees.push({
				feeType: "FloodInsurance",
				monthlyPayment: floodInsurance,
			});

			updatedFees.push({
				feeType: "PropertyTaxes",
				monthlyPayment: propertyTaxes,
			});
		} else {
			applicationData?.fees?.map((fee) => {
				if (fee.feeType === "FloodInsurance") {
					updatedFees.push({ ...fee, monthlyPayment: floodInsurance });
				}

				if (fee.feeType === "PropertyTaxes") {
					updatedFees.push({ ...fee, monthlyPayment: propertyTaxes });
				}

				return fee;
			});
		}

		console.log("430 updated fees:", updatedFees);

		setApplicationData({
			...applicationData,
			subjectPropertyGrossRentalIncomeAmount: grossRent,
			proposedHazardInsuranceAmount: homeownersInsurance,
			proposedDuesAmount: hoaDues,
			fees: updatedFees,
			proposedRealEstateTaxesAmount:
				updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
					?.monthlyPayment || "0",
			applications,
			property: propertyInfo,
			tPO: {
				lOEmail: loanOriginatorEmail,
				lPEmail: loanProcessorEmail,
			},
		});

		console.log("305 loan guid:", loanGuid);

		let apiObj = {
			...applicationData,
			subjectPropertyGrossRentalIncomeAmount: grossRent,
			proposedHazardInsuranceAmount: homeownersInsurance,
			proposedDuesAmount: hoaDues,
			fees: updatedFees,
			proposedRealEstateTaxesAmount:
				updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
					?.monthlyPayment || "0",
			applications: applications[0],
			property: propertyInfo,
		};

		// update loan api
		console.log("510 loan id:", loanGuid);
		console.log("510 loan data:", apiObj);

		console.log("430 application data:", applicationData);
		console.log("430 applications:", applications);

		console.log("475 api obj:", {
			...applicationData,
			subjectPropertyGrossRentalIncomeAmount: grossRent,
			proposedHazardInsuranceAmount: homeownersInsurance,
			proposedDuesAmount: hoaDues,
			fees: updatedFees,
			proposedRealEstateTaxesAmount:
				updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
					?.monthlyPayment || "0",
			applications,
		});

		const loanUpdateResponse = await updateLoan({
			loanGuid,
			loanData: {
				subjectPropertyGrossRentalIncomeAmount: grossRent,
				proposedHazardInsuranceAmount: homeownersInsurance,
				proposedDuesAmount: hoaDues,
				fees: updatedFees,
				proposedRealEstateTaxesAmount:
					updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
						?.monthlyPayment || "0",
				property: propertyInfo,
				tpo: {
					loEmail: loanOriginator?.user?.email,
					loName: `${loanOriginator?.user?.first_name} ${loanOriginator?.user?.last_name}`,
					loBusinessPhone: loanOriginator?.phone,
					loAeName: loanOriginator?.organization?.sales_rep_id,
					companyId: loanOriginator?.organization?.company_id,
					companyOrganizationId: loanOriginator?.organization?.organization_id,
					companyName: loanOriginator?.organization?.name,
					companyLegalName: loanOriginator?.organization?.company_legal_name,
					companyAeName: loanOriginator?.organization?.sales_rep_name,
					companyAddress: loanOriginator?.organization?.address,
					companyCity: loanOriginator?.organization?.city,
					companyState: loanOriginator?.organization?.state,
					companyZip: loanOriginator?.organization?.zip,
					companyPhone: loanOriginator?.organization?.phone,
				},
				originatorFirstName: loanOriginator?.user?.first_name,
				originatorLastName: loanOriginator?.user?.last_name,
				originatorAddressLineText: loanOriginator?.organization?.address,
				nmlsLoanOriginatorId: loanOriginator?.nmls_id,
				interviewerEmail: loanOriginator?.user?.email,
				interviewerName: `${loanOriginator?.user?.first_name} ${loanOriginator?.user?.last_name}`,
				interviewerPhoneNumber: loanOriginator?.phone,
				interviewerLicenseIdentifier: "",
			},
		}).unwrap();

		console.log("440 loan update res:", loanUpdateResponse);

		console.log("440 borrower pair data:", borrowerPairData);
		console.log("440 borrower response data:", borrowerResponseData);

		let borrowerPair;

		for (let index = 0; index < borrowerPairData.length; index++) {
			console.log("445 index:", index);
			console.log(
				"450 borrower pair id:",
				borrowerResponseData[index]?.borrowerPairId
			);

			if (
				borrowerResponseData[index]?.borrowerPairId !== null &&
				borrowerResponseData[index]?.borrowerPairId !== undefined &&
				borrowerResponseData[index]?.borrowerPairId !== ""
			) {
				// if borrower pair id exists then update the pair
				try {
					borrowerPair = await updateBorrowerPair({
						loanGuid: loanGuid,
						borrowerPairId: borrowerResponseData[index].borrowerPairId,
						loanData: { ...borrowerPairData[index][0] },
					}).unwrap();
				} catch (err) {
					console.log("450 err:", err);
					if (err?.data?.details?.includes("was not found")) {
						// if borrower pair id does not exists then create the pair
						borrowerPair = await createBorrowerPair({
							loanGuid: loanGuid,
							data: { ...borrowerPairData[index][0] },
						}).unwrap();
					} else {
						setError(err?.data?.details);
					}
				}
			} else {
				// if borrower pair id does not exists then create the pair
				borrowerPair = await createBorrowerPair({
					loanGuid: loanGuid,
					data: { ...borrowerPairData[index][0] },
				}).unwrap();
			}
		}

		console.log("550 update borrower pair data:", borrowerPair);

		if (borrowerPair !== null && borrowerPair !== undefined) {
			updateField(loanGuid, apiObj);
		}
	};

	const handleAddBorrowerPair = () => {
		setLoanApplicationData([...loanApplicationData, {}, {}]);
	};

	const handleRemovePair = async () => {
		console.log("470 pair count:", loanApplicationData?.length / 2 - 1);

		if (tabValue === loanApplicationData?.length / 2 - 1) {
			setTabValue(tabValue - 1);
		}

		let newArr = loanApplicationData;

		if (tabValue === 0) {
			newArr?.splice(tabValue, 2);
		} else {
			newArr?.splice(tabValue * 2, 2);
		}

		setLoanApplicationData([...newArr]);
	};

	const handleRemoveBorrowerPair = async () => {
		console.log("485 tab value:", tabValue);
		console.log("485 borrower id:", borrowerResponseData[tabValue]?.id);

		let deleteBorrowerPairResponse;

		if (borrowerResponseData[tabValue]?.id !== undefined) {
			// if pair exists on loan, remove borrower pair after deleting from the loan
			try {
				deleteBorrowerPairResponse = await deleteBorrowerPair({
					loanGuid: loanGuid,
					borrowerPairId: borrowerResponseData[tabValue]?.id,
					loanData: {},
				}).unwrap();

				console.log("475 delete:", deleteBorrowerPairResponse);

				if (deleteBorrowerPairResponse?.message?.includes("success")) {
					handleRemovePair();
				}
			} catch (err) {
				console.log("515 err:", err);
				if (err?.data?.details?.includes("was not found")) {
					handleRemovePair();
				} else if (
					err?.data?.details?.includes(
						"Loan should have at least one application associated"
					)
				) {
					// show modal with error for deleting borrower pair
					console.log("520 err:", err);
					handleOpenErrorModal();
				}
			}
		} else {
			// if pair is new and does not exist on loan, remove pair without running API
			handleRemovePair();
		}
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	// loader - loadingBorrowerPairData
	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				<Tabs
					value={tabValue}
					onChange={handleChangeTab}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						style: {
							backgroundColor: "#76D99E",
						},
					}}
				>
					<Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Borrower Pair 1"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>

								{tabValue === 0 && (
									<Stack
										className={styles.closeIconContainer}
										onClick={() =>
											loanApplicationData?.length > 2 &&
											handleRemoveBorrowerPair()
										}
									>
										<CloseOutlined className={styles.closeIcon} />
									</Stack>
								)}
							</Stack>
						}
						{...a11yProps(0)}
					/>
					{loanApplicationData?.length > 2 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 1 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() => handleRemoveBorrowerPair()}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(1)}
						/>
					)}
					{loanApplicationData?.length > 4 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 3"
										fontType="semibold"
										extraClass={
											tabValue === 2 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 2 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() => handleRemoveBorrowerPair()}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(2)}
						/>
					)}

					<Box>
						<IconButton
							className={styles.addPairContainer}
							onClick={() =>
								loanApplicationData?.length < 6 && handleAddBorrowerPair()
							}
							disabled={loanApplicationData?.length > 4}
						>
							<AddOutlined className={styles.addPairIcon} />
						</IconButton>
					</Box>
				</Tabs>

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 0 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 4 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>
					</TabPanel>
				)}
			</Stack>

			{error !== "" && (
				<RequiredError
					headerText={error.includes("Loan is locked") ? "" : "Missing fields"}
					text={
						error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted above."
							: error.includes("Loan is locked")
							? "This loan is currently being edited by Logan Finance. Once Logan Finance exits the loan, you will be able to navigate the loan in the system and the data displayed will be updated as needed."
							: error.includes("borrower.dependentCount") &&
							  "Please check your dependent count."
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<PrimaryButton
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>

			{/* modals */}
			<LoaderModal
				open={
					loadingBorrowerPairData ||
					loadingloanData ||
					isFetchingFields ||
					isUpdatingFields ||
					updatingLoan ||
					updatingBorrowerPair ||
					creatingBorrowerPair ||
					deletingBorrowerPair ||
					brokerListLoading ||
					organizationDetailsLoading
				}
			/>
			<ErrorModal
				open={errorModalVisible}
				text="Unable to delete the primary borrower pair"
				handleClose={handleCloseErrorModal}
			/>
			<HelpTextModal
				open={helpTextOpen}
				modalText={helpText}
				handleClose={handleCloseHelpText}
			/>
		</Stack>
	);
};

export default EditLoanApplication;
