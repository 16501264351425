import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// mui components
import { Stack, TableCell, TableRow } from "@mui/material";

// custom components
import Body1 from "../../typography/body-01";

// custom icons
import WarningIcon from "../../../assets/icons/warning-icon";

// redux slice
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// custom styles
import styles from "../index.module.scss";

const AdminPipelineRow = ({ row, handleOpenConfirmModal }) => {
	const navigate = useNavigate();
	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const loanStatus =
		row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
			? "Started - Not Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Started"
			? "Loan Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Loan Submitted"
			? "Loan Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Submission"
			? "Review Submission"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] ===
			  "Incomplete Credit Pkg"
			? "Incomplete Credit Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Cred Pkg"
			? "Recd Cred Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Cred Pkg"
			? "Recd Cred Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "File Setup"
			? "File Setup"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Submit to UW"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "In UWing Review"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Suspended"
			? "Suspended"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Cond'l Approval"
			? "Conditional Approval"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Conditions"
			? "Recd Conditions"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "UW Cond Review"
			? "UW Cond Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Requested"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Complete"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "CTC"
			? "Clear to Close"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing Prep"
			? "Closing Docs"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing"
			? "Closing"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding Prep"
			? "Wire Sent"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding" && "Funded";

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row?.id}
			onClick={() =>
				row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
					? userDetails?.user_roles?.includes("admin") &&
					  (impersonatedUser?.email === undefined || impersonatedUser?.email === null)
						? handleOpenConfirmModal()
						: navigate(`/edit-loan/${row?.loanGuid || row?.loanId}`)
					: navigate(`/loan-summary/${row?.loanGuid || row?.loanId}`)
			}
		>
			{/* loan number */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1
					text={
						row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
							? "Not Assigned"
							: row?.fields["Loan.LoanNumber"]
					}
					error={row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"}
					isLink={row?.fields["Loan.LoanFolder"] !== "Portal - Not Submitted"}
					extraClass={styles.pipelineNumber}
				/>
			</TableCell>

			{/* loan status */}
			<TableCell key={row?.id} align={row?.align}>
				{row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted" ? (
					<Stack
						direction="row"
						// spacing={1}
						className={styles.incompleteStatusContainer}
					>
						<WarningIcon />
						<Body1
							text="Incomplete Application"
							error={
								row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
							}
						/>
					</Stack>
				) : (
					<Body1 text={loanStatus} />
				)}
			</TableCell>

			{/* start date */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1
					text={
						row?.fields["Fields.2025"] !== ""
							? row?.fields["Fields.2025"]?.split(" ")[0]
							: ""
					}
				/>
			</TableCell>

			{/* borrower name */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1 text={row?.fields["Loan.BorrowerName"]} />
			</TableCell>

			{/* property address */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1
					text={`${row?.fields["Fields.URLA.X73"]}, ${row?.fields["Fields.URLA.X75"]}, ${row?.fields["Fields.12"]}, ${row?.fields["Fields.14"]} `}
				/>
			</TableCell>

			{/* loan program */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1 text={row?.fields["Fields.1401"]} />
			</TableCell>

			{/* expiration date */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1
					text={
						row?.fields["Fields.762"] !== ""
							? row?.fields["Fields.762"]?.split(" ")[0]
							: ""
					}
				/>
			</TableCell>

			{/* loan folder */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1 text={row?.fields["Loan.LoanFolder"]} />
			</TableCell>

			{/* loan TPO */}
			<TableCell key={row?.id} align={row?.align}>
				<Body1 text={row?.fields["Fields.TPO.X14"]} />
			</TableCell>
		</TableRow>
	);
};

export default AdminPipelineRow;
