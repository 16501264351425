import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// mui components
import {
	Autocomplete,
	Box,
	Checkbox,
	Stack,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
} from "@mui/material";

// mui utils
import { visuallyHidden } from "@mui/utils";

// helper functions
import {
	sortAmount,
	sortDate,
	sortLoanStatus,
} from "../../../utils/sort-methods";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import Heading4 from "../../typography/heading-04";

// reducer slice
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// helper utils
import {
	loanFolderOptions,
	cbMilestoneList,
	ndcMilestoneList,
	corrMilestoneList,
} from "../../../utils/select-options";
import { selectedUserWithLoanChannel } from "../../../utils/common-helpers";
import { LOAN_CHANNEL_CONSTANTS } from "../../../utils/common-constants";

// custom styles
import styles from "./index.module.scss";

const TableHeadPipeline = (props) => {
	const {
		data,
		rowData,
		// sort
		order,
		orderBy,
		onRequestSort,
		filteredLoans,
		setFilteredLoans,
	} = props;

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const searchParams = queryParams.get("status");

	console.log("42 search params:", searchParams);

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const [loanStatusOptions, setLoanStatusOptions] = useState([]);

	useEffect(() => {
		if (
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC
		) {
			setLoanStatusOptions(ndcMilestoneList?.map((item) => item.label));
		} else if (
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
		) {
			setLoanStatusOptions(corrMilestoneList?.map((item) => item.label));
		} else {
			setLoanStatusOptions(cbMilestoneList?.map((item) => item.label));
		}
	}, [userDetails, impersonatedUser]);

	const [firstRender, setFirstRender] = useState(true);

	const [selectedLoanFolder, setSelectedLoanFolder] = useState([]);
	const [selectedLoanStatus, setSelectedLoanStatus] = useState([]);

	const [searchValue, setSearchValue] = useState({
		borrowerName: "",
		loanAmount: "",
		loanFolder: "",
		loanNumber: "",
		loanProgram: "",
		loanStatus: "",
		lockExpirationDate: "",
		propertyAddress: "",
		salesRep: "",
		startDate: "",
		tpo: "",
	});

	const createSortHandler = (property) => (event) => {
		let fieldId;

		if (property === "number") {
			fieldId = "fields[Loan.LoanNumber]";
		} else if (property === "expiration-date") {
			fieldId = "fields[Fields.762]";
		} else if (property === "days-to-expiry") {
			fieldId = "fields[Fields.432]";
		} else if (property === "program") {
			fieldId = "fields[Fields.1401]";
		} else if (property === "name") {
			fieldId = "fields[Loan.BorrowerName]";
		} else if (property === "folder") {
			fieldId = "fields[Loan.LoanFolder]";
		} else if (property === "status") {
			fieldId = "fields[Fields.Log.MS.CurrentMilestone]";
		} else if (property === "start-date") {
			fieldId = "fields[Fields.2025]";
		} else if (property === "address") {
			fieldId = "fields[Fields.URLA.X73]";
		} else if (property === "label") {
			fieldId = "title";
		} else if (property === "upload-date") {
			fieldId = "createdDate";
		}

		let sortedData;

		if (property.includes("date")) {
			console.log("105 sort date:", order, fieldId);
			sortedData = sortDate(filteredLoans, order, fieldId);
		} else if (fieldId === "fields[Fields.Log.MS.CurrentMilestone]") {
			console.log("110 sort status:", order, fieldId);
			sortedData = sortLoanStatus(rowData, order, fieldId);
		} else {
			console.log("115 sort amount:", order, fieldId);
			sortedData = sortAmount(filteredLoans, order, fieldId);
		}

		console.log("100 sorted data:", sortedData);
		setFilteredLoans(sortedData);
		onRequestSort(event, property);
	};

	useEffect(() => {
		let searchedLoans = [];

		console.log("115 row data:", rowData);
		console.log("115 search value:", searchValue);

		searchedLoans = rowData.filter((item) => {
			let statusValue = "";

			if (item?.fields["Loan.LoanFolder"] === "Portal - Not Submitted") {
				statusValue = "Started - Not Submitted";
			} else if (item?.fields["Fields.Log.MS.CurrentMilestone"] !== "") {
				// Use a switch statement to map current milestones to statuses
				switch (item?.fields["Fields.Log.MS.CurrentMilestone"]) {
					case "Started":
						statusValue = "File Started";
						break;
					case "Review Cred Pkg":
						statusValue = "Recd Cred Pkg";
						break;
					case "Submit to UW":
						statusValue = "In Underwriting";
						break;
					case "In UWing Review":
						statusValue = "In Underwriting";
						break;
					case "Cond'l Approval":
						statusValue = "Cond's Approval";
						break;
					case "QC Requested":
						statusValue = "In Underwriting";
						break;
					case "QC Complete":
						statusValue = "In Underwriting";
						break;
					case "CTC":
						statusValue = "Clear to Close";
						break;
					case "Closing Prep":
						statusValue = "Closing Docs";
						break;
					case "Funding Prep":
						statusValue = "Wire Sent";
						break;
					case "Funding":
						statusValue = "Funded";
						break;
					default:
						statusValue = item?.fields["Fields.Log.MS.CurrentMilestone"];
						break;
				}
			} else {
				statusValue = "";
			}

			let propertyAddressValue =
				item?.fields["Fields.URLA.X73"]?.toLowerCase() +
				"," +
				item?.fields["Fields.URLA.X75"]?.toLowerCase() +
				"," +
				item?.fields["Fields.12"]?.toLowerCase() +
				"," +
				item?.fields["Fields.14"]?.toLowerCase();

			return (
				propertyAddressValue
					.toString()
					?.includes(searchValue?.propertyAddress?.toLowerCase()) &&
				item?.fields["Fields.2025"]
					?.split(" ")[0]
					?.toString()
					?.includes(searchValue?.startDate) &&
				item.fields["Loan.BorrowerName"]
					?.toLowerCase()
					?.includes(searchValue?.borrowerName?.toLowerCase()) &&
				item.fields["Loan.LoanAmount"]?.includes(searchValue?.loanAmount) &&
				(searchValue?.loanStatus?.length > 0
					? searchValue?.loanStatus
							.map((status) => status.toLowerCase())
							.includes(statusValue.toLowerCase())
					: true) &&
				item.fields["Loan.LoanAmount"]?.includes(
					searchValue?.salesRep?.toLowerCase()
				) &&
				(item?.fields["Loan.LoanFolder"] !== "Portal - Not Submitted"
					? // loan number
					  item.fields["Loan.LoanNumber"]?.includes(searchValue?.loanNumber)
					: // incomplete loans
					  "Incomplete"
							?.toLowerCase()
							?.includes(searchValue?.loanNumber?.toLowerCase())) &&
				item.fields["Fields.1401"]
					?.toLowerCase()
					?.includes(searchValue?.loanProgram?.toLowerCase()) &&
				item?.fields["Fields.762"]
					?.split(" ")[0]
					?.toString()
					?.includes(searchValue?.lockExpirationDate) &&
				(item.fields["Fields.TPO.X14"] !== undefined
					? item.fields["Fields.TPO.X14"]
							?.toLowerCase()
							?.includes(searchValue?.tpo?.toLowerCase())
					: true) &&
				(searchValue?.loanFolder?.length > 0
					? searchValue?.loanFolder?.includes(item.fields["Loan.LoanFolder"])
					: true)
			);
		});

		console.log("250 searched loans:", searchedLoans);
		setFilteredLoans(searchedLoans);
	}, [searchValue]);

	const handleSearchTable = (value, name) => {
		console.log("100 value:", value);
		console.log("100 name:", name);

		let updatedSearchValue = { ...searchValue, [name]: value };
		setSearchValue(updatedSearchValue);

		console.log("100 updated search value:", updatedSearchValue);
	};

	const handleSelectFolder = (selectedList) => {
		handleSearchTable(selectedList, "loanFolder");
		setSelectedLoanFolder(selectedList);
	};

	const handleSelectStatus = (selectedList) => {
		handleSearchTable(selectedList, "loanStatus");
		setSelectedLoanStatus(selectedList);
	};

	useEffect(() => {
		if (firstRender && rowData?.length > 0) {
			console.log("280 search params:", searchParams);

			if (
				searchParams !== null &&
				searchParams !== undefined &&
				searchParams !== ""
			) {
				setSearchValue((prevState) => ({
					...prevState,
					loanStatus: searchParams,
				}));

				setSelectedLoanFolder(["Pipeline"]);
				setSelectedLoanStatus([searchParams]);

				setSearchValue((prevState) => ({
					...prevState,
					loanFolder: ["Pipeline"],
					loanStatus: [searchParams],
				}));
			} else {
				handleSelectFolder(["Pipeline", "Portal - Not Submitted"]);
			}
			setFirstRender(false);
		}
	}, [rowData]);

	return (
		<TableHead>
			<TableRow>
				{data.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ minWidth: headCell.minWidth }}
					>
						<Stack direction="column" className={styles.headerStack}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								className={styles.tableLabel}
							>
								<Heading4
									text={headCell.label}
									extraClass={styles.thText}
									fontType="semibold"
								/>
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
							{headCell.label === "Loan Folder" ? (
								<Autocomplete
									sx={{
										"& .MuiOutlinedInput-root": {
											paddingTop: "0px",
											paddingLeft: "0px",
											paddingBottom: "0px",
										},
									}}
									multiple
									limitTags={1}
									id="checkboxes-tags"
									className={styles.inputField}
									options={loanFolderOptions}
									value={selectedLoanFolder}
									onChange={(event, newValue) => {
										handleSelectFolder(newValue);
									}}
									getOptionLabel={(option) => option}
									renderOption={(props, option, { selected }) => (
										<li {...props}>
											<Checkbox style={{ marginRight: 8 }} checked={selected} />
											{option}
										</li>
									)}
									style={{ width: 300, display: "flex", alignItems: "center" }}
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													borderWidth: "1px !important",
													borderColor: "#000 !important",
													borderRadius: "2px",
												},
											}}
											InputLabelProps={{ shrink: false }}
											placeholder="Search here..."
										/>
									)}
								/>
							) : headCell.label === "Loan Status" ? (
								<Autocomplete
									sx={{
										"& .MuiOutlinedInput-root": {
											paddingTop: "0px",
											paddingLeft: "0px",
											paddingBottom: "0px",
										},
									}}
									multiple
									limitTags={1}
									id="checkboxes-tags"
									className={styles.inputField}
									options={loanStatusOptions}
									value={selectedLoanStatus}
									onChange={(event, newValue) => {
										handleSelectStatus(newValue);
									}}
									getOptionLabel={(option) => option}
									renderOption={(props, option, { selected }) => (
										<li {...props}>
											<Checkbox style={{ marginRight: 8 }} checked={selected} />
											{option}
										</li>
									)}
									style={{ width: 300, display: "flex", alignItems: "center" }}
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													borderWidth: "1px !important",
													borderColor: "#000 !important",
													borderRadius: "2px",
												},
											}}
											InputLabelProps={{ shrink: false }}
											placeholder="Search here..."
										/>
									)}
								/>
							) : (
								<OutlinedTextInput
									extraClass={styles.inputField}
									placeholder="Search here..."
									name={
										headCell.label === "Property Address"
											? "propertyAddress"
											: headCell.label === "Start Date"
											? "startDate"
											: headCell.label === "Borrower Name"
											? "borrowerName"
											: headCell.label === "Loan Amount"
											? "loanAmount"
											: headCell.label === "Sales Rep."
											? "salesRep"
											: headCell.label === "Loan Number"
											? "loanNumber"
											: headCell.label === "Lock Expiration Date"
											? "lockExpirationDate"
											: headCell.label === "Loan Program"
											? "loanProgram"
											: headCell.label === "TPO" && "tpo"
									}
									value={
										headCell.label === "Property Address"
											? searchValue?.propertyAddress || ""
											: headCell.label === "Start Date"
											? searchValue?.startDate || ""
											: headCell.label === "Borrower Name"
											? searchValue?.borrowerName || ""
											: headCell.label === "Loan Amount"
											? searchValue?.loanAmount || ""
											: headCell.label === "Loan Status"
											? searchValue?.loanStatus || ""
											: headCell.label === "Sales Rep."
											? searchValue?.salesRep || ""
											: headCell.label === "Loan Number"
											? searchValue?.loanNumber || ""
											: headCell.label === "Lock Expiration Date"
											? searchValue?.lockExpirationDate
											: headCell.label === "Loan Program"
											? searchValue?.loanProgram
											: headCell.label === "TPO"
											? searchValue?.tpo
											: ""
									}
									handleChange={(event) =>
										headCell.label === "Loan Number"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.LoanNumber"
											  )
											: headCell.label === "Property Address"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.URLA.X73"
											  )
											: headCell.label === "Start Date"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.2025"
											  )
											: headCell.label === "Borrower Name"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.BorrowerName"
											  )
											: headCell.label === "Loan Amount"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.LoanAmount"
											  )
											: headCell.label === "Sales Rep."
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.LoanAmount"
											  )
											: headCell.label === "Lock Expiration Date"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.762"
											  )
											: headCell.label === "Loan Program"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.1401"
											  )
											: headCell.label === "TPO" &&
											  handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.TPO.X14"
											  )
									}
								/>
							)}
						</Stack>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadPipeline;
