import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { utils, writeFileXLSX } from "xlsx";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

// api slice
import { selectUserDetails } from "../../features/auth/authSlice";
import { useGetPipelineMutation } from "../../features/pipeline/pipelineApiSlice";
import { useGetMilestoneCountMutation } from "../../features/milestone-count/milestoneCountApiSlice";
import { useGetAdminPipelineMutation } from "../../features/admin/pipeline/pipelineApiSlice";
import { useGetAdminUnfilteredPipelineMutation } from "../../features/admin/pipeline/unfilteredLoanApiSlice";
import { useGetUnfilteredMilestoneCountMutation } from "../../features/admin/unfiltered-milestone/unfilteredMilestoneApiSlice";
import { useGetFilteredMilestoneCountMutation } from "../../features/admin/filtered-milestone/filteredMilestoneApiSlice";
import { useGetAeMilestoneCountMutation } from "../../features/admin/account-executive-milestone-count/ae-milestone-count";
import { useGetAmMilestoneCountMutation } from "../../features/admin/account-manager-milestone-count/am-milestone-count";
import { useGetAmPipelineMutation } from "../../features/admin/pipeline/amPipelineApiSlice";
import { useGetAePipelineMutation } from "../../features/admin/pipeline/aePipelineApiSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// custom components
import CustomLayout from "../../components/layout";
import CustomTable from "../../components/custom-table";
import Heading4 from "../../components/typography/heading-04";
import SecondaryButton from "../../components/buttons/secondary-button";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import ErrorModal from "../../components/modal/error-modal";

//helper utils
import { selectedUserWithLoanChannel } from "../../utils/common-helpers";
import { LOAN_CHANNEL_CONSTANTS } from "../../utils/common-constants";
import {
	cbMilestoneList,
	corrMilestoneList,
	ndcMilestoneList,
} from "../../utils/select-options";

// custom styles
import styles from "./index.module.scss";

export default function HomePage() {
	const [getPipeline, { isLoading: brokerLoading }] = useGetPipelineMutation();

	const [getMilestoneCount] = useGetMilestoneCountMutation();

	const [getFilteredMilestoneCount] = useGetFilteredMilestoneCountMutation();

	const [getUnfilteredMilestoneCount] =
		useGetUnfilteredMilestoneCountMutation();

	const [getAeMilestoneCount] = useGetAeMilestoneCountMutation();

	const [getAmMilestoneCount] = useGetAmMilestoneCountMutation();

	const [getAdminPipeline, { isLoading: adminLoading }] =
		useGetAdminPipelineMutation();

	const [getAdminUnfilteredPipeline, { isLoading: adminUnfilteredLoading }] =
		useGetAdminUnfilteredPipelineMutation();

	const [getAmPipeline, { isLoading: amPipelineLoading }] =
		useGetAmPipelineMutation();

	const [getAePipeline, { isLoading: aePipelineLoading }] =
		useGetAePipelineMutation();

	const navigate = useNavigate();
	const [loans, setLoans] = useState([]);
	const [rateLockLoading, setRateLockLoading] = useState(false);

	const [loanStatusLoading, setLoanStatusLoading] = useState(true);
	const [milestoneCountData, setMilestoneCountData] = useState({});

	const [loanStatusData, setLoanStatusData] = useState([
		{ label: "Loan Submitted", count: 0 },
		{ label: "Review Submission", count: 0 },
		{ label: "Incomplete Credit Pkg", count: 0 },
		{ label: "Recd Cred Pkg", count: 0 },
		{ label: "File Setup", count: 0 },
		{ label: "UW Cond Review", count: 0 },
		{ label: "In Underwriting", count: 0 },
		{ label: "Suspended", count: 0 },
		{ label: "Cond's Approval", count: 0 },
		{ label: "Recd Conditions", count: 0 },
		{ label: "Clear to Close", count: 0 },
		{ label: "Closing Docs", count: 0 },
		{ label: "Closing", count: 0 },
		{ label: "Wire Sent", count: 0 },
		{ label: "Funded", count: 0 },
	]);

	const [errorModalVisible, setErrorModalVisible] = useState(false);
	const [errorText, setErrorText] = useState("");

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	useEffect(() => {
		async function getAdminPipelineData() {
			console.log("67 user roles:", userDetails?.user_roles);
			console.log("130 impersonated user:", impersonatedUser?.email);

			setRateLockLoading(true);

			if (
				userDetails?.user_roles?.includes("admin") === true &&
				impersonatedUser?.email !== undefined &&
				impersonatedUser?.email !== null &&
				impersonatedUser?.email !== ""
			) {
				// Admin, impersonated user APIs
				console.log("150 pipeline impersonated user");
				console.log("28 org name:", impersonatedUser);
				try {
					const pipelineAdminData = await getAdminPipeline({
						brokerEmail: impersonatedUser?.email,
						loan_channel: impersonatedUser?.loan_channel,
					}).unwrap();
					console.log(
						"65 impersonated pipeline data:",
						pipelineAdminData?.data
					);
					setRateLockLoading(false);
					setLoans(pipelineAdminData?.data);

					const filteredMilestoneData = await getFilteredMilestoneCount({
						brokerEmail: impersonatedUser?.email,
						loan_channel: impersonatedUser?.loan_channel,
					}).unwrap();
					console.log(
						"65 impersonated pipeline data:",
						filteredMilestoneData?.data
					);
					setMilestoneCountData(filteredMilestoneData?.data);
				} catch (error) {
					setRateLockLoading(false);
					handleOpenErrorModal();
					setErrorText("Error loading data.");
				}
			} else if (
				(impersonatedUser?.email === undefined ||
					impersonatedUser?.email === null) &&
				userDetails?.user_roles?.includes("admin") === true
			) {
				// Admin APIs
				console.log("150 pipeline admin");
				try {
					const pipelineAdminLoanData =
						await getAdminUnfilteredPipeline().unwrap();
					console.log("65 admin pipeline data:", pipelineAdminLoanData?.data);
					setRateLockLoading(false);
					setLoans(pipelineAdminLoanData?.data);

					const unfilteredMilestoneData =
						await getUnfilteredMilestoneCount().unwrap();
					console.log(
						"65 admin milestone data:",
						unfilteredMilestoneData?.data
					);
					setMilestoneCountData(unfilteredMilestoneData?.data);
				} catch (error) {
					setRateLockLoading(false);
					handleOpenErrorModal();
					setErrorText("Error loading data.");
				}
			} else if (
				userDetails?.user_roles?.includes("manager") ||
				userDetails?.user_roles?.includes("AM")
			) {
				// account manager APIs
				console.log("150 pipeline manager");
				try {
					const pipelineData = await getAmPipeline().unwrap();
					console.log("65 account manager pipeline data:", pipelineData?.data);
					setRateLockLoading(false);
					setLoans(pipelineData?.data);

					const milestoneCountData = await getAmMilestoneCount().unwrap();
					console.log("65 admin milestone data:", milestoneCountData?.data);
					setMilestoneCountData(milestoneCountData?.data);
				} catch (error) {
					setRateLockLoading(false);
					handleOpenErrorModal();
					setErrorText("Error loading data.");
				}
			} else if (
				userDetails?.user_roles?.includes("executive") ||
				userDetails?.user_roles?.includes("AE")
			) {
				// account executive APIs
				console.log("150 pipeline executive");
				try {
					const pipelineData = await getAePipeline().unwrap();
					console.log(
						"65 account executive pipeline data:",
						pipelineData?.data
					);
					setRateLockLoading(false);
					setLoans(pipelineData?.data);

					const milestoneCountData = await getAeMilestoneCount().unwrap();
					console.log("65 admin milestone data:", milestoneCountData?.data);
					setMilestoneCountData(milestoneCountData?.data);
				} catch (error) {
					setRateLockLoading(false);
					handleOpenErrorModal();
					setErrorText("Error loading data.");
				}
			} else if (userDetails?.user_roles?.includes("admin") === false) {
				// broker APIs
				console.log("150 pipeline broker");
				try {
					const brokerPipelineData = await getPipeline().unwrap();
					console.log("65 broker milestone data:", brokerPipelineData?.data);
					setRateLockLoading(false);
					setLoans(brokerPipelineData?.data);

					const milestonePipelineData = await getMilestoneCount().unwrap();
					console.log("65 broker milestone data:", milestonePipelineData?.data);
					setMilestoneCountData(milestonePipelineData?.data);
				} catch (error) {
					setRateLockLoading(false);
					handleOpenErrorModal();
					setErrorText("Error loading data.");
				}
			}
		}

		getAdminPipelineData();
	}, [userDetails, impersonatedUser]);

	useEffect(() => {
		if (
			userDetails?.user_roles?.includes("admin") === true &&
			impersonatedUser?.email?.length <= 0
		) {
			navigate("/admin");
		}
	}, [userDetails, impersonatedUser]);

	useEffect(() => {
		if (milestoneCountData !== undefined) {
			console.log("130 milestoneCountData:", milestoneCountData);

			if (
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC
			) {
				let newArr = ndcMilestoneList.map((item) => {
					const count = milestoneCountData[item.milestone];
					return count !== undefined ? { ...item, count: count } : item;
				});

				setLoanStatusLoading(false);
				setLoanStatusData(newArr);
			} else if (
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
			) {
				let newArr = corrMilestoneList.map((item) => {
					const count = milestoneCountData[item.milestone];
					return count !== undefined ? { ...item, count: count } : item;
				});

				setLoanStatusLoading(false);
				setLoanStatusData(newArr);
			} else {
				let newArr = cbMilestoneList;

				const milestoneCountArr = Object.entries(milestoneCountData).map(
					(e) => ({
						label: e[0],
						count: e[1],
					})
				);

				for (let index = 0; index < milestoneCountArr.length; index++) {
					const element = milestoneCountArr[index];

					let objIndex;
					if (element.label === "Started") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Loan Submitted"
						);
					} else if (element.label === "Loan Submitted") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Loan Submitted"
						);
					} else if (element.label === "Review Submission") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Review Submission"
						);
					} else if (element.label === "Incomplete Credit Pkg") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Incomplete Credit Pkg"
						);
					} else if (element.label === "Recd Cred Pkg") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Recd Cred Pkg"
						);
					} else if (element.label === "Review Cred Pkg") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Recd Cred Pkg"
						);
					} else if (element.label === "File Setup") {
						objIndex = newArr.findIndex((obj) => obj?.label === "File Setup");
					} else if (element.label === "Submit to UW") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "In Underwriting"
						);
					} else if (element.label === "In UWing Review") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "In Underwriting"
						);
					} else if (element.label === "Suspended") {
						objIndex = newArr.findIndex((obj) => obj?.label === "Suspended");
					} else if (element.label === "Cond'l Approval") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Cond's Approval"
						);
					} else if (element.label === "Recd Conditions") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Recd Conditions"
						);
					} else if (element.label === "UW Cond Review") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "UW Cond Review"
						);
					} else if (element.label === "QC Requested") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "In Underwriting"
						);
					} else if (element.label === "QC Complete") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "In Underwriting"
						);
					} else if (element.label === "CTC") {
						objIndex = newArr.findIndex(
							(obj) => obj?.label === "Clear to Close"
						);
					} else if (element.label === "Closing Prep") {
						objIndex = newArr.findIndex((obj) => obj?.label === "Closing Docs");
					} else if (element.label === "Closing") {
						objIndex = newArr.findIndex((obj) => obj?.label === "Closing");
					} else if (element.label === "Funding Prep") {
						objIndex = newArr.findIndex((obj) => obj?.label === "Wire Sent");
					} else if (element.label === "Funding") {
						objIndex = newArr.findIndex((obj) => obj?.label === "Funded");
					}

					if (newArr[objIndex] !== undefined) {
						// Update object's name property.
						newArr[objIndex].count += element.count;
					}
				}

				setLoanStatusLoading(false);
				setLoanStatusData(newArr);
			}
		}
	}, [milestoneCountData]);

	const loanStatusColumns = [
		{ id: "label", label: "Loan Status", minWidth: 180 },
		{ id: "count", label: "Count", minWidth: 60 },
	];

	const rateLockColumns = [
		{ id: "number", label: "Loan Number", minWidth: 170 },
		{ id: "expiration-date", label: "Expiration Date", minWidth: 170 },
		{ id: "days-to-expiry", label: "Days to Expire", minWidth: 170 },
		{ id: "program", label: "Loan Program", minWidth: 675 },
		{ id: "name", label: "Borrower Name", minWidth: 300 },
		{ id: "folder", label: "Loan Folder", minWidth: 180 },
		{ id: "status", label: "Loan Status", minWidth: 275 },
	];

	const handleExportExcelClick = () => {
		const loanData = loans.map(({ loanGuid, fields }) => ({
			loanId: loanGuid,
			loanFolder: fields["Loan.LoanFolder"],
			loanNumber: fields["Loan.LoanNumber"],
			loanAmount: fields["Loan.LoanAmount"],
			borrowerName: fields["Loan.BorrowerName"],
			propertyAddress:
				fields["Fields.URLA.X73"] +
				"," +
				fields["Fields.URLA.X75"] +
				"," +
				fields["Fields.12"] +
				"," +
				fields["Fields.14"],
			loanStatus: fields["Fields.Log.MS.CurrentMilestone"],
			// salesRep: fields[''],
			lockExpirationDate: fields["Fields.762"].slice(0, 10),
			loanProgram: fields["Fields.1401"],
		}));

		const ws = utils.json_to_sheet(loanData);
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "Data");
		writeFileXLSX(wb, "Exported Excel.xlsx");
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="row"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					{/* loan status */}
					<Stack direction="column" className={styles.tableContainer}>
						<Box className={styles.loanTableHeader}>
							<Heading4 text="Loan Status - Active Loans" fontType="semibold" />
						</Box>

						<CustomTable
							tableType="loan-status"
							columnData={loanStatusColumns}
							rowData={loanStatusData}
						/>
					</Stack>
					{/* rate lock status */}
					<Stack direction="column" className={styles.tableContainer}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							className={styles.tableTitleContainer}
						>
							<Heading4 text="Rate Lock Status" fontType="semibold" />

							<SecondaryButton
								text="Export to Excel"
								startIcon={
									<DownloadOutlinedIcon className={styles.downloadIcon} />
								}
								onClick={handleExportExcelClick}
							/>
						</Stack>

						<CustomTable
							columnData={rateLockColumns}
							rowData={loans?.filter(
								(loan) =>
									loan?.fields["Loan.LoanFolder"] === "Pipeline" ||
									loan?.fields["Loan.LoanFolder"] === "Funded" ||
									loan?.fields["Loan.LoanFolder"] === "Adverse Loans"
							)}
							enableSort={true}
							tableType="rate-lock"
							isLoading={
								brokerLoading ||
								adminLoading ||
								adminUnfilteredLoading ||
								amPipelineLoading ||
								aePipelineLoading
							}
							setSortedData={setLoans}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			<LoaderModal open={rateLockLoading || loanStatusLoading} />
			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
		</Box>
	);
}
